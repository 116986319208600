import request from '@/utils/request'


// 查询用户上线提醒列表
export function listOnlineReminder(query) {
  return request({
    url: '/user/user-online-reminder/list',
    method: 'get',
    params: query
  })
}

// 查询用户上线提醒分页
export function pageOnlineReminder(query) {
  return request({
    url: '/user/user-online-reminder/page',
    method: 'get',
    params: query
  })
}

// 查询用户上线提醒详细
export function getOnlineReminder(data) {
  return request({
    url: '/user/user-online-reminder/detail',
    method: 'get',
    params: data
  })
}

// 新增用户上线提醒
export function addOnlineReminder(data) {
  return request({
    url: '/user/user-online-reminder/add',
    method: 'post',
    data: data
  })
}

// 修改用户上线提醒
export function updateOnlineReminder(data) {
  return request({
    url: '/user/user-online-reminder/edit',
    method: 'post',
    data: data
  })
}

// 删除用户上线提醒
export function delOnlineReminder(data) {
  return request({
    url: '/user/user-online-reminder/delete',
    method: 'post',
    data: data
  })
}
